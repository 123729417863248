/* ArtistHeader.css */
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: "Arial", sans-serif;
  background-color: #edeef9;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7c7cff;
  padding: 20px 0;
  background-image: linear-gradient(
    120deg,
    #4c4c7f,
    #2c5a45
  ); /* Darkened gradient */

  position: relative;
  overflow: hidden;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  width: 100%;
  box-sizing: border-box;
}

.logo {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 40px;
}

.nav li {
  cursor: pointer;
  color: #fff;
  font-size: 16px;
}

.artist-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.artist-image {
  height: auto;
  object-fit: cover;
  margin-left: 30px;
  width: 50%;
  position: relative;
  z-index: 1;
}

.artist-info h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #fff;
  text-align: left;
  font-weight: 800;
}

.artist-info p {
  font-size: 24px;
  margin-bottom: 5px;
  color: #fff;
  text-align: left;
}
.artist-info span {
  font-size: 24px;
  margin-bottom: 5px;
  color: #fff;
  text-align: left;
  font-weight: 800;
}

.artist-bio {
  font-size: 18px;
  color: #fff;
  text-align: left;
  max-width: 98%;
}
.artist-image:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(
    transparent,
    #7c7cff,
    #4c9469
  ); /* Overlay gradient matching the background for fade effect */
  z-index: 2;
}

/* Background shapes with low opacity */
.shape1,
.shape2,
.shape3,
.shape4,
.shape5 {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 0;
}

.shape1 {
  top: 10%;
  left: 5%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: radial-gradient(
    transparent,
    rgba(255, 255, 255, 0.3)
  ); /* For a touch of radial depth */
}

.shape2 {
  top: 5.5%;
  right: 10%;
  width: 350px;
  height: 350px;
  transform: rotate(45deg);
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3)
  ); /* Diagonal gradient */
}

.shape3 {
  bottom: 5%;
  left: 10%;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.shape4 {
  bottom: 20%;
  right: 5%;
  width: 100px;
  height: 100px;
  transform: rotate(45deg);
  background-image: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.5)
  ); /* Stronger diagonal gradient */
}

.shape5 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: transparent;
  border: 5px solid rgba(255, 255, 255, 0.1); /* Thin circular border */
}
.social-icons {
  display: flex;
  gap: 100px;
  margin-top: 15px;
}
.playlist-container {
  width: 73%;
  margin: 0 auto; /* This will center your container */
  background-color: white; /* As per your request for a white background */
  padding-bottom: 10px; /* Giving some padding for aesthetics */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); /* Optional shadow for a little depth */
  margin-top: 60px;
}

iframe {
  width: 100% !important; /* To ensure it takes the full width of its parent container */
  border: none; /* To remove any border */
}

.song-container {
  margin-bottom: 30px;
}

.song-logos {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  margin-top: 20px;
}

.before-text-icon {
  margin-right: 10px; /* Space between the icon and the "Available on" text */
  color: #28a745; /* Verification green, adjust if needed */
  margin-left: 30px;
}

.available-text {
  margin-right: 20px;
  font-weight: bold;
  font-size: 16px;
}
.menu-item-home {
  font-weight: 900;
}
@media (max-width: 768px) {
  /* Hiding menu items */
  .menu-item-home,
  .menu-item-music,
  .menu-item-playlist,
  .menu-item-radio {
    display: none;
  }

  /* Navigation layout for mobile */
  .nav {
    flex-direction: column;
    padding: 20px 0;
  }

  .logo {
    margin-bottom: 20px;
  }

  .nav ul {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  /* Artist section layout for mobile */
  .artist-section {
    flex-direction: column-reverse;
    padding-top: 30px;
  }

  .artist-image {
    margin-left: 0; /* Remove the left margin for mobile */
    width: 100%; /* Make the image full width on mobile */
    margin-bottom: 20px;
  }

  .artist-info h1 {
    font-size: 32px;
  }

  .artist-info p {
    font-size: 20px;
  }

  .artist-bio {
    font-size: 16px;
    max-width: 100%;
  }

  /* Hiding the shapes on mobile for clarity */
  .shape1,
  .shape2,
  .shape3,
  .shape4,
  .shape5 {
    display: none;
  }

  .social-icons {
    gap: 50px;
  }

  .playlist-container {
    width: 90%;
  }

  .song-logos {
    gap: 15px;
    justify-content: center; /* Centering the logos on mobile */
  }

  /* Hiding the available text and icon on mobile for simplicity */
  .before-text-icon,
  .available-text {
    display: none;
  }
}
.artist-section {
  display: flex;
  flex-direction: row; /* This ensures the image is to the right on desktop */
}

.artist-image {
  order: 2; /* This will place the image to the right */
}

.artist-info {
  order: 1; /* This will place the artist info to the left */
}

@media (max-width: 768px) {
  .artist-section {
    flex-direction: column-reverse; /* This moves the image to the top on mobile */
  }
}
